// react
import React, { useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import theme from "../../data/theme";
import { FormattedMessage, useIntl } from "react-intl";
import { useWindowSize } from "../../services/utils";
// application
import PageHeader from "../shared/PageHeader";

// data stubs

function SitePageAboutUs() {
  const intl = useIntl();
  const breadcrumb = [
    { title: "Home", url: "/" },
    { title: "About Us", url: "" },
  ];
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize.width < 1330;
  const mobileScreen = windowSize.width < 576;
  const currentLanguage = localStorage.getItem("LG");
  const startYear = 2007;

  const [expandedYear, setExpandedYear] = useState(null);

  const aboutUsText = [
    { text: intl.formatMessage({ id: "aboutUs.body1" }) }, { text: intl.formatMessage({ id: "aboutUs.body2" }) },
    { text: intl.formatMessage({ id: "aboutUs.body3" }) }, { text: intl.formatMessage({ id: "aboutUs.body4" }) },
    { text: intl.formatMessage({ id: "aboutUs.body5" }) }, { text: intl.formatMessage({ id: "aboutUs.body6" }) },
  ]

  const visionMissionList = [
    { header: intl.formatMessage({ id: "aboutUs.mission_title" }), subtext: intl.formatMessage({ id: "aboutUs.mission" }) },
    { header: intl.formatMessage({ id: "aboutUs.vision_title" }), subtext: intl.formatMessage({ id: "aboutUs.vision" }) },
  ]

  const achievementList = [
    { img: "/images/icons/member_icon.png", alt: "Member Icon", text: intl.formatMessage({ id: "aboutUs.companyachievement_member" }) },
    { img: "/images/icons/outlet.png", alt: "Outlet Icon", text: intl.formatMessage({ id: "aboutUs.companyachievement_outlet" }) },
    { img: "/images/icons/employee.png", alt: "Employee Icon", text: intl.formatMessage({ id: "aboutUs.companyachievement_employees" }) },
    { img: "/images/icons/business.png", alt: "Business Icon", text: intl.formatMessage({ id: "aboutUs.companyachievement_business_1" }) + (new Date().getFullYear() - startYear) + intl.formatMessage({ id: "aboutUs.companyachievement_business_2" }) },
    { img: "/images/icons/supplier_icon.png", alt: "Suppliers Icon", text: intl.formatMessage({ id: "aboutUs.companyachievement_suppliers" }) },
  ]

  const coreValueList = [
    { img: "/images/icons/honesty.png", alt: "Honesty Icon", text: intl.formatMessage({ id: "aboutUs.corevalues_h" }), subtext: intl.formatMessage({ id: "aboutUs.corevalues_honesty" }) },
    { img: "/images/icons/services.png", alt: "Service Icon", text: intl.formatMessage({ id: "aboutUs.corevalues_s" }), subtext: intl.formatMessage({ id: "aboutUs.corevalues_services" }) },
    { img: "/images/icons/satisfaction_icon.png", alt: "Satisfaction Icon", text: intl.formatMessage({ id: "aboutUs.corevalues_satisfaction" }), subtext: intl.formatMessage({ id: "aboutUs.corevalues_satisfactions" }), subtextstyle: { width: '70%' } },
    { img: "/images/icons/focus_fairness.png", alt: "Focus and Fairness Icon", text: intl.formatMessage({ id: "aboutUs.corevalues_f" }), subtext: intl.formatMessage({ id: "aboutUs.corevalues_focus" }) },
    { img: "/images/icons/teamwork.png", alt: "Teamwork Icon", text: intl.formatMessage({ id: "aboutUs.corevalues_t" }), subtext: intl.formatMessage({ id: "aboutUs.corevalues_teamwork" }) },
    { img: "/images/icons/scored.png", alt: "Scored Icon", text: intl.formatMessage({ id: "aboutUs.corevalues_score" }), subtext: intl.formatMessage({ id: "aboutUs.corevalues_scored" }) },
    { img: "/images/icons/being_the_best.png", alt: "Being The Best Icon", text: intl.formatMessage({ id: "aboutUs.corevalues_b" }), subtext: intl.formatMessage({ id: "aboutUs.corevalues_being_the_best" }) },
  ]

  const flowList = [
    { header: intl.formatMessage({ id: "aboutUs.certified_heading" }), subtext: intl.formatMessage({ id: "aboutUs.certified_text" }), arrowsrc: "/images/icons/left-arrow.png", arrowalt: "Left Arrow Icon" },
    { header: intl.formatMessage({ id: "aboutUs.indoor_pig_farming_heading" }), subtext: intl.formatMessage({ id: "aboutUs.indoor_pig_farming_text" }), arrowsrc: "/images/icons/left-arrow.png", arrowalt: "Left Arrow Icon" },
    { header: intl.formatMessage({ id: "aboutUs.controlled_environment_heading" }), subtext: intl.formatMessage({ id: "aboutUs.controlled_environment_text" }), arrowsrc: "", arrowalt: "" },
    { header: intl.formatMessage({ id: "aboutUs.careful_selection_heading" }), subtext: intl.formatMessage({ id: "aboutUs.careful_selection_text" }), arrowsrc: "/images/icons/right-arrow.png", arrowalt: "Right Arrow Icon" },
    { header: intl.formatMessage({ id: "aboutUs.sourcing_heading" }), subtext: intl.formatMessage({ id: "aboutUs.sourcing_text" }), arrowsrc: "/images/icons/curved-arrow.png", arrowalt: "Curved Arrow Icon" },
  ]

  const featureList = [
    { text: intl.formatMessage({ id: "aboutUs.key_features_fresh" }), style: "circleboxblue", textStyle: { width: currentLanguage == "ZH" ? "50%" : "" } },
    { text: intl.formatMessage({ id: "aboutUs.key_features_rigorous" }), style: "circleboxred" },
    { text: intl.formatMessage({ id: "aboutUs.key_features_free" }), style: "circleboxblue" },
    { text: intl.formatMessage({ id: "aboutUs.key_features_advance" }), style: "circleboxred" },
    { text: intl.formatMessage({ id: "aboutUs.key_features_transportation" }), style: mobileScreen ? "circleboxblue" : "circleboxred" },
    { text: intl.formatMessage({ id: "aboutUs.key_features_qualified_pork" }), style: mobileScreen ? "circleboxred" : "circleboxblue" },
    { text: intl.formatMessage({ id: "aboutUs.key_features_qualified_inspection" }), style: mobileScreen ? "circleboxblue" : "circleboxred" },
    { text: intl.formatMessage({ id: "aboutUs.key_features_guarantee" }), style: mobileScreen ? "circleboxred" : "circleboxblue", textStyle: { width: currentLanguage == "ZH" ? "50%" : "" } },
  ]

  const distributionImgList = [
    { src: "/images/web-images/distribution1.png" }, { src: "/images/web-images/distribution2.png" }, { src: "/images/web-images/distribution3.png" }
  ]

  const merchandiseImgList = [
    { src: "/images/web-images/merchandise1.png" }, { src: "/images/web-images/merchandise2.png" }, { src: "/images/web-images/merchandise3.png" },
    { src: "/images/web-images/merchandise4.png" }, { src: "/images/web-images/merchandise5.png" }, { src: "/images/web-images/merchandise6.png" },
  ]

  const certificationImgList = [
    { src: "/images/web-images/haccp.png", style: "" }, { src: "/images/web-images/vhm.png", style: "custom-size" },
    // { src: "/images/web-images/gmp.png", style: "" }, { src: "/images/web-images/mesti.png", style: "" },
  ]

  const milestones = [
    { year: 2007, places: ["Lobak"] },
    { year: 2008, places: ["Mantin", "Blossom"] },
    { year: 2009, places: ["Semenyih", "Bahau", "Lukut", "Titi"] },
    { year: 2010, places: ["Tampin"] },
    { year: 2011, places: ["Limbok"] },
    { year: 2012, places: ["Triang"] },
    { year: 2013, places: ["Serdang"] },
    { year: 2014, places: ["Klang", "Cheras"] },
    { year: 2017, places: ["Puchong"] },
    { year: 2018, places: ["Mahkota Cheras", "Yulek Cheras"] },
    { year: 2019, places: ["Bentong"] },
    { year: 2020, places: ["Jenjarom"] },
    { year: 2021, places: ["Tangkak", "Kajang Prima"] },
    { year: 2022, places: ["Segamat"] },
    { year: 2024, places: ["Muar", "Oakland (relocate from Lobak)"] },
  ];

  const partnerLogo = [
    { src: "/images/logos/hupsoon_app.png", alt: "HupSoon Apps" },
    { src: "/images/icons/grab_food.png", alt: "Grab Food Apps" },
    { src: "/images/icons/food_panda_icon.png", alt: "Food Panda Apps" },
  ]

  const handleToggle = (year) => {
    setExpandedYear(expandedYear === year ? null : year);
  };

  const renderArrowIcon = (src, alt, index) => {
    return (
      <div className="">
        {isSmallScreen ? (
          index == flowList.length - 1 ? "" :
            <img src="/images/icons/down-arrow.png" alt="Down Arrow Icon" className="arrow-icon" />
        ) : src ?
          <img src={src} alt={alt} className="arrow-icon" />
          : ""}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${intl.formatMessage({ id: "topbar.aboutUs", defaultMessage: "About Us" })} — ${theme.name}`}</title>
      </Helmet>

      <PageHeader breadcrumb={breadcrumb} />
      <div className="block">
        <div className="container">
          <div className="document">
            <div className="document__content typography">
              <div className="mb-3">
                <h2 style={{ color: "#2e2e2e", textAlign: "center" }}>
                  <FormattedMessage id="topbar.aboutUs" defaultMessage="About Us" />
                </h2>
              </div>

              <div className="text-justify">
                {aboutUsText.map((item, index) => (
                  <>
                    <div key={index}>{item.text}</div>
                    <p></p>
                  </>
                ))}
              </div>

              <div style={{ padding: "20px 0px", marginBottom: "10rem" }}>
                <video width="100%" controls>
                  <source
                    src={"https://hupsoonfood.com/videos/hupsoon-promo.mp4"}
                    type="video/mp4"
                  />
                </video>
              </div>

              <div className="personbox">
                <div className="personimg">
                  <img src="/images/web-images/person1.png" alt="Person" className="person-image" />
                  <div style={{ textAlign: 'center', marginTop: '10px' }}>
                    <div style={{ fontSize: '24px' }}>
                      <FormattedMessage id="aboutUs.ceoName" />
                    </div>
                    <div style={{ fontSize: '17px', fontStyle: currentLanguage == "EN" ? 'italic' : 'normal' }}>
                      <FormattedMessage id="aboutUs.ceoPosition" />
                    </div>
                  </div>
                </div>
                <div className="persontextbox">
                  <div className="persontext">
                    <FormattedMessage id="aboutUs.person" />
                  </div>
                </div>
              </div>

              <div className="company-achievements">
                <h2 className="achievement-title">
                  <FormattedMessage id="aboutUs.companyachievement" />
                </h2>
                <div className="achievement-grid">
                  {achievementList.map((item, index) => (
                    <div className="achievement-item" key={index}>
                      <div className="achievement-content">
                        <img src={item.img} alt={item.alt} className="achievement-icon" />
                        <p>{item.text}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="vmbox-container">
                {visionMissionList.map((item, index) => (
                  <div className="vmbox-item" key={index}>
                    <h5 className="vmbox-title">{item.header}</h5>
                    <p className="vmbox-text">{item.subtext}</p>
                  </div>
                ))}
              </div>

              <h2 className="corevalues-title">
                <FormattedMessage id="aboutUs.corevalues" />
              </h2>
              <div className="corevalues-container">
                <div className="corevalues">
                  {coreValueList.map((item, index) => (
                    <div className="corevalue-item" key={index}>
                      <img src={item.img} alt={item.alt} className="corevalue-icon" />
                      <p className="corevaluestext mb-0">{item.text}</p>
                      <p className="mb-0" style={item.subtextstyle && currentLanguage == "ZH" ? item.subtextstyle : {}}>{item.subtext}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div className={`certified-farm-container ${isSmallScreen ? "column-layout" : "row-layout"}`}>
                <h2 className="certified-farm-title">
                  <FormattedMessage id="aboutUs.certified_farm" />
                </h2>
                <div className="certifiedbox">
                  {flowList.map((item, index) => (
                    <>
                      <div className="farmcircle-box" key={index}>
                        <h5 className="mt-2">{item.header}</h5>
                        <p>{item.subtext}</p>
                      </div>
                      {renderArrowIcon(item.arrowsrc, item.arrowalt, index)}
                    </>
                  ))}
                </div>
              </div>

              <div className="certified-no-borderbox">
                <h2 className="feature-title">
                  <FormattedMessage id="aboutUs.key_features" />
                </h2>
                <div className="feature-circles">
                  {featureList.map((item, index) => (
                    <div className={item.style} key={index}>
                      <p style={item.textStyle}>{item.text}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="distribution-no-borderbox">
                <h2 className="distribution-text">
                  <FormattedMessage id="aboutUs.distribution" />
                </h2>
                <div className="distribution-merchandise-no-borderbox">
                  {distributionImgList.map((item, index) => (
                    <img src={item.src} key={index} alt={`distribution-img-${index + 1}`} />
                  ))}
                </div>
              </div>

              <div className="merchandising-no-borderbox">
                <h2 className="distribution-text">
                  <FormattedMessage id="aboutUs.merchandising" />
                </h2>
                <div className="distribution-merchandise-no-borderbox">
                  {merchandiseImgList.map((item, index) => (
                    <img src={item.src} key={index} alt={`merchandise-img-${index + 1}`} />
                  ))}
                </div>
              </div>

              <div className="certification-no-border">
                <h2 style={{ color: "#2e2e2e", textAlign: "center" }}>
                  <FormattedMessage id="aboutUs.certifications" />
                </h2>
                <div className="certification-images">
                  {certificationImgList.map((item, index) => (
                    <img src={item.src} className={item.style} alt={`certification-img-${index + 1}`} key={index} />
                  ))}
                </div>
              </div>

              <div className="milestones-container">
                <h2 style={{ color: "#2e2e2e" }}>
                  <FormattedMessage id="aboutUs.business_milestones" />
                </h2>
                <p style={{ fontWeight: 'bold', fontSize: '20px' }}>2007 - 2024</p>
                <div className="milestones">
                  {milestones.map((milestone) => (
                    <div
                      key={milestone.year}
                      className="milestone"
                      onClick={() => handleToggle(milestone.year)}
                    >
                      <div className="year">{milestone.year}</div>
                      {expandedYear === milestone.year && (
                        <ul>
                          {milestone.places.map((place, index) => (
                            <li key={index}>{place}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="joinus">
                <img
                  src="/images/web-images/phone_example.png"
                  alt="Phone Example"
                  className="joinus-image"
                />
                <div className="joinus-content">
                  <h2 className="joinus-heading mt-0" style={{ color: "#ff3333" }}>
                    <FormattedMessage id="aboutUs.join_us" />
                  </h2>
                  <p className="joinus-text">Hup Soon MY</p>
                  <p className="joinus-text">
                    <FormattedMessage id="aboutUs.looking_order" />
                  </p>
                  <h3 className="joinus-text" style={{ color: "#ff3333" }}>
                    <FormattedMessage id="aboutUs.order_online" />
                  </h3>
                  <div className="app-images-container">
                    {partnerLogo.map((item, index) => (
                      <img src={item.src} alt={item.alt} className="app-image" key={index} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SitePageAboutUs;
